import * as Constants from './constants'

const CryptoJS = require('crypto-js')

export function extractPayload (payload) {
  let decrypted = '--error--'
  try {
    const strPwd = Constants.HASH_KEY
    const strIv = Constants.HASH_IV

    const iv = CryptoJS.SHA256(strIv).toString().substring(0, 16)
    const key = CryptoJS.SHA256(strPwd).toString().substring(0, 32)

    const decodedPayload = CryptoJS.enc.Base64.parse(payload).toString(CryptoJS.enc.Utf8)

    const keyObj = CryptoJS.enc.Utf8.parse(key)
    const ivObj = CryptoJS.enc.Utf8.parse(iv)

    decrypted = CryptoJS.AES.decrypt(decodedPayload, keyObj, {
      iv: ivObj,
      mode: CryptoJS.mode.CBC
    }).toString(CryptoJS.enc.Utf8)

    if (decrypted.trim().length == 0) {
      decrypted = '--error--'
    }

    return decrypted
  } catch (e) {
    console.error(e)
  }
  return decrypted
}

export function getFontFamily (locale) {
  switch (locale) {
    case 'ja':
    case 'jp':
      return 'mplus1p'
    case 'cn':
      return 'notoSansSC'
    case 'zh':
    case 'zh-HK':
    case 'zh-TW':
      return 'notoSansTC'
    case 'ko':
    case 'kr':
      return 'ibmPlex'
    default:
      return 'abcMonumentGrotesk'
  }
}

export function getLeapLocale (locale) {
  let l
  if (locale.includes('zh')) {
    l = locale
  } else {
    l = locale.substring(0, 2)
  }
  if (l.localeCompare('bs') === 0) {
    return 'ba'
  } else if (l.localeCompare('cs') === 0) {
    return 'cz'
  } else if (l.localeCompare('ja') === 0) {
    return 'jp'
  } else if (l.localeCompare('ko') === 0) {
    return 'kr'
  } else if (l.localeCompare('sl') === 0) {
    return 'si'
  } else if (l.localeCompare('sq') === 0) {
    return 'al'
  } else if (l.localeCompare('sr') === 0) {
    return 'rs'
  } else if (l.localeCompare('zh-TW') === 0 || l.localeCompare('zh-HK') === 0) {
    return 'zh'
  } else if (l.localeCompare('zh') === 0 || l.localeCompare('zh-CN') === 0) {
    return 'cn'
  } else if (l.localeCompare('el') === 0) {
    return 'gr'
  } else if (l.localeCompare('kk') === 0) {
    return 'kz'
  } else if (l.localeCompare('uk') === 0) {
    return 'ua'
  }

  return l
}

export function isMobile () {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  return regex.test(navigator.userAgent)
}
